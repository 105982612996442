import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/Seo'

const Pravidla = (props) => (
    <Layout>
        <SEO
        title="Pravidla"
        description="Pravidla šifrovací hry Navíc, která se koná v Praze 8. června 2019."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Pravidla</h2>
                    </header>
                    <ul>
                        <li>Šifrovací hra Navíc se koná 8. června 2019 v Praze.</li>
                        <li>Účastní se týmy o počtu 1–4 členů. Za určitých podmínek vás může být i 5*.</li>
                        <li>Startovné činí 350 Kč a kapacita hry je <s>120 týmů</s>. Změna: Kapacitu hry jsme se rozhodli neomezovat.</li>
                        <li>Účastnický poplatek je třeba uhradit do středy 5. června do 12.00. Platební údaje naleznete po registraci v sekci <Link to="/vas-tym">Váš tým</Link>.</li>
                    </ul>
                    <p className="small">* Na hru můžete vzít pátého hráče navíc, pokud <Link to="/faq#paty">nemá zkušenosti</Link> ze šifrovacích her. Dále se do počtu hráčů nepočítají děti do 12 let věku včetně.</p>

                    <h3>Formát hry</h3>
                    <ul>
                        <li>Hra obsahuje start, 9 stanovišť hlavní trasy a neznámý počet <em>úkolů navíc</em>.</li>
                        <li>Plněním <em>úkolů navíc</em> týmy získávají body, maximálně lze získat 100 bodů.</li>
                    </ul>

                    <h3>Start a cíl</h3>
                    <ul>
                        <li>Startujeme v 8.30* na <a href="https://mapy.cz/s/3ptCr">vrchu Třešňovka</a> u MHD Spojovací.</li>
                        <li>Končíme ve 20.30** ve <a href="https://mapy.cz/s/3p4b6">skautské klubovně Pitterova</a>.</li>
                        <li>V cíli bude pivo, limo, táborák a deskovky.</li>
                        <li>V cíli můžete i přespat (cca 60 míst), registrujte se zavčas.</li>
                    </ul>

                    <p className="small">* Se startem počkáme na hráče jedoucí vlaky rj 574 Brněnský drak (odjezd 4.28 z Brna) a RJ 1040 RegioJet (odjezd v 5.00 z Brna). Pokud by se zpoždění vlaků počítalo na hodiny, sledujte webinfo.</p>
                    <p className="small">** Čas konce hry může být organizátory před začátkem nebo v průběhu hry posunut na pozdější dobu, maximálně však o 30 minut.</p>

                    <h3>Hlavní trasa</h3>
                    <ul>
                        <li>Stanoviště hlavní trasy na sebe navazují lineárně a jsou postupně označena celými čísly 1–9. V případě, že je zadání rozděleno na více částí, je toto naznačeno vložením dalšího symbolu za číslo stanoviště (př. 3a, 3b).</li>
                        <li>Řešením šifer hlavní trasy je poloha následujícího stanoviště. Výjimkou je stanoviště č. 9, jehož řešením je cílové heslo. </li>
                    </ul>

                    <h3>Úkoly navíc</h3>
                    <ul>
                        <li>Informace o <em>úkolech navíc</em> jsou poskytovány na hlavní trase hry v <Link to="/faq#nezasifrovane-ukoly">nezašifrované podobě</Link>.</li>
                        <li><em>Úkoly navíc</em> na sebe mohou a nemusí navazovat a jsou postupně označeny písmeny anglické abecedy. V případě, že je zadání rozděleno na více částí, je toto naznačeno vložením dalšího symbolu za písmeno stanoviště (př. e1, e2).</li>
                        <li>Pokud je řešením <em>úkolu navíc</em> heslo, zadává ho tým do <Link to="/faq#webinfo">webového systému</Link> hry.</li>
                        <li>Záhlaví <em>úkolů navíc</em> obsahují informaci o počtech bodů, které tým získá jejich splněním.</li>
                    </ul>

                    <h3>Nápovědní systém</h3>
                    <ul>
                        <li>U šifer na hlavní trase se za nápovědy platí body za splnění <em>úkolů navíc</em>.</li>
                        <li>Informace o možnostech získat nápovědu či řešení šifry se nacházejí v záhlaví každého zadání na hlavní trase a specifikují cenu v bodech a čas, po kterém lze těchto možností využít.</li>
                        <li>Lze vzít řešení bez využití nápovědy. Při využití nápovědy i řešení se body za využití sčítají.</li>
                        <li>K <em>úkolům navíc</em> nápovědy a řešení za body získat nelze.</li>
                        <li>Informace v záhlaví zadání mohou vypadat například takto:</li>
                    </ul>

                    <div className="table-wrapper">
                        <table className="alt">
                            <tbody>
                            <tr>
                                <td>
                                <strong>Nápověda:</strong><br />
                                5 bodů po 20 minutách nebo<br />
                                2 body po 60 minutách
                                </td>
                                <td>
                                <strong>Řešení:</strong><br />
                                4 body po 90 minutách nebo<br />
                                0 bodů po 120 minutách
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className="small">Ve výše uvedeném příkladu může tým získat nápovědu k šifře nejdříve po dvaceti minutách na stanovišti za cenu 5 bodů. Po hodině na stanovišti se cena snižuje na 2 body. Obdobně může tým šifru po 90 minutách přeskočit výměnou za 4 body, případně zadarmo po 120 minutách. Časy a ceny hlídá webový systém, který před uplatněním jakékoli nápovědy či přeskočení potvrdí cenu, za kterou nápovědu nebo přeskočení čerpáte. Lze utratit pouze body, které máte, nelze jít do mínusu.</p>

                    <h3>Vyhodnocení</h3>
                    <ul>
                        <li>Hru dokončí tým, který projde všemi stanovišti hlavní trasy a v cíli alespoň jeden jeho člen sdělí organizátorům správné cílové heslo.</li>
                        <li>Hra obsahuje <Link to="/faq#kategorie">dvě kategorie</Link> hodnocení:</li>
                    </ul>

                    <div className="table-wrapper">
                        <table className="alt">
                            <tbody>
                            <tr>
                                <td>
                                <strong>NA čas:</strong><br />
                                Vítězem je tým, který dokončí hru první bez ohledu na body, použité nápovědy a přeskočení stanovišť.
                                </td>
                                <td>
                                <strong>VÍC bodů:</strong><br />
                                Vítězem je tým, který dokončí hru s nejvyšším počtem bodů na kontě v okamžiku odevzdání cílového hesla.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <a name="co-ano-co-ne"></a>
                    <h3>Povolené a zakázané postupy</h3>
                    <ul>
                    <li>Je povoleno tým kdykoli rozdělit, avšak maximálně na dvě části.</li>
                    <li>Je povoleno brát si na stanovištích 2 kopie zadání na tým.</li>
                    <li>Je povoleno používat libovolnou výpočetní, záznamovou a navigační techniku.</li>
                    <li>Je povoleno přesouvat se pěšky, v kočárku a prostředky veřejné linkové dopravy včetně přívozů, lanovky, vlaků a pravidelných leteckých spojů.</li>
                    <li>Je zakázáno přesouvat se způsoby nejmenovanými v předchozím bodě.</li>
                    <li>Je přikázáno hrát fér*. :)</li>
                    </ul>
                    <p className="small">* Nesledujte cizí týmy, netipujte umístění dalšího stanoviště bez vyluštění šifry, neporušujte pravidla. Kazíte tím zábavu sobě i ostatním hráčům. Pokud pochybujete, jestli je něco fér, tak spíš není.</p>

                </div>
            </section>
        </div>

    </Layout>
)

export default Pravidla