import React from 'react'
import RawHTML from './rawHTML'

class DiscussionComment extends React.Component {
	render() {
		const comment = this.props.comment
		return (
			<div className="navic_comment" key={comment.id}>
				<div className="navic_comment_header">
					<b>{comment.author}</b>{comment.team_name ? ' (' + comment.team_name + ')' : null}
				</div>
				<div dangerouslySetInnerHTML={{__html: comment.message}}></div>
				<div className="navic_comment_footer">
					{comment.timestamp}
				</div>
			</div>
		)
	}
}
export default DiscussionComment
