import React from 'react'

class FormErrors extends React.Component {
    render() {
        const errors = this.props.errors || [];
        const successMessage = this.props.successMessage || '';

        const errorListElements = errors.map( (error) => 
            <li key={error}>
                {error}
            </li>
        );

        const errorsDom = errors.length ? (
            <ul id="form-errors">
                {errorListElements}
            </ul>
        ) : null

        const successDom = successMessage ? (
            <div id="form-success">
                {successMessage}
            </div>
        ) : null

        return (
            <>
                {successDom}
                {errorsDom}
            </>
        )
    }
}

export default FormErrors