import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'

class Vysledky extends React.Component {
    constructor() {
        super();
	}

	render() {
		return (
			<Layout>
			<SEO
			title="Výsledky"
			description="Výsledky šifrovačky Navíc, která se konala v Praze 8. června 2019."
			/>
				<div id="main">
					<section id="one">
						<div className="inner">
							<header className="major">
								<h2>Výsledky</h2>
							</header>
							<p>Do oficiální výsledkové listiny jsou zařazeny týmy, které odevzdaly cílové heslo. Na neoficiální výsledky a další zajímavá data se můžete podívat <a href="https://statek.seslost.cz/navic-2019/poradi">na Statku</a>.</p>

							<h3>Pořadí v kategorii <em>Na čas</em></h3>
							<p>(Pořadí pro kategorii <em>Víc bodů</em> najdete níž na stránce)</p>

<table className="nobreak"><thead><tr>
<th>#</th>
<th>Název týmu</th>
<th>Cíl</th>
<th>Body</th></tr></thead><tbody><tr>
<td>1.</td>
<td><b>O stupeň navíc</b></td>
<td>14:52</td>



<td>34</td></tr>
<tr>
<td>2.</td>
<td>Spící Volové</td>
<td>14:57</td>



<td>27</td></tr>
<tr>
<td>3.</td>
<td>Ztraceni na severu</td>
<td>15:10</td>



<td>2</td></tr>
<tr>
<td>4.</td>
<td>Degen a spol</td>
<td>16:54</td>



<td>10</td></tr>
<tr>
<td>5.</td>
<td>Red Alert</td>
<td>16:59</td>



<td>39</td></tr>
<tr>
<td>6.</td>
<td>Společenští poustevníci</td>
<td>17:06</td>



<td>16</td></tr>
<tr>
<td>7.</td>
<td>3 s kouskem fosforu</td>
<td>17:25</td>



<td>24</td></tr>
<tr>
<td>8.</td>
<td>Happy Matfyz Friends</td>
<td>17:27</td>



<td>19</td></tr>
<tr>
<td>9.</td>
<td>Na výletě</td>
<td>17:40</td>



<td>21</td></tr>
<tr>
<td>10.</td>
<td>mrkve z tykve</td>
<td>17:43</td>



<td>21</td></tr>
<tr>
<td>11.</td>
<td>Nic Nás Nenapadlo</td>
<td>17:50</td>



<td>15</td></tr>
<tr>
<td>12.</td>
<td>FOKA</td>
<td>18:07</td>



<td>21</td></tr>
<tr>
<td>13.</td>
<td>poTrati</td>
<td>18:52</td>



<td>6</td></tr>
<tr>
<td>14.</td>
<td>Stokaři</td>
<td>19:20</td>



<td>25</td></tr>
<tr>
<td>15.</td>
<td>Krabice ve slevě</td>
<td>19:24</td>



<td>19</td></tr>
<tr>
<td>16.</td>
<td>Hakuna Matata</td>
<td>19:40</td>



<td>31</td></tr>
<tr>
<td>17.</td>
<td>Kdo se přidá?</td>
<td>19:48</td>



<td>3</td></tr>
<tr>
<td>18.</td>
<td>Team Avatar</td>
<td>20:07</td>



<td>92</td></tr>
<tr>
<td>19.</td>
<td>Kvapící Fronkáros</td>
<td>20:10</td>



<td>17</td></tr>
<tr>
<td>20.</td>
<td>nechci-uvést</td>
<td>20:28</td>



<td>49</td></tr>
<tr>
<td>21.</td>
<td>V.I.P.</td>
<td>20:29</td>



<td>89</td></tr>
<tr>
<td>22.</td>
<td>O.H.L.A.</td>
<td>20:31</td>



<td>25</td></tr>
<tr>
<td>23.</td>
<td>Za pět minut dvanáct</td>
<td>20:37</td>



<td>30</td></tr>
<tr>
<td>24.</td>
<td>Zebra řez</td>
<td>20:39</td>



<td>43</td></tr>
<tr>
<td>25.</td>
<td>Kočky</td>
<td>20:41</td>



<td>38</td></tr>
<tr>
<td>26.</td>
<td>Kočka s Out of memory errorem</td>
<td>20:42</td>



<td>81</td></tr>
<tr>
<td>27.</td>
<td>Wake of Gods</td>
<td>20:44</td>



<td>63</td></tr>
<tr>
<td>28.</td>
<td>Tranquilo, señor!</td>
<td>20:44</td>



<td>73</td></tr>
<tr>
<td>29.</td>
<td>Večernice</td>
<td>20:45</td>



<td>10</td></tr>
<tr>
<td>30.</td>
<td>Život je změna spinu</td>
<td>20:47</td>



<td>11</td></tr>
<tr>
<td>31.</td>
<td>Subdivize XVIII</td>
<td>20:49</td>



<td>2</td></tr>
<tr>
<td>32.</td>
<td>Freezy Bee</td>
<td>20:49</td>



<td>40</td></tr>
<tr>
<td>33.</td>
<td>Trosečníci</td>
<td>20:50</td>



<td>50</td></tr>
<tr>
<td>34.</td>
<td>Klokani hledají poklady</td>
<td>20:50</td>



<td>48</td></tr>
<tr>
<td>35.</td>
<td>Poslední operand</td>
<td>20:51</td>



<td>59</td></tr>
<tr>
<td>36.</td>
<td>Šestý nesmysl</td>
<td>20:53</td>



<td>72</td></tr>
<tr>
<td>37.</td>
<td>B</td>
<td>20:53</td>



<td>70</td></tr>
<tr>
<td>38.</td>
<td>Kanonický divisor</td>
<td>20:53</td>



<td>37</td></tr>
<tr>
<td>39.</td>
<td>Dršťkový Havran Masového Kašpárka</td>
<td>20:53</td>



<td>36</td></tr>
<tr>
<td>40.</td>
<td>Psychokopytníci</td>
<td>20:54</td>



<td>46</td></tr>
<tr>
<td>41.</td>
<td>Směs oříšků</td>
<td>20:57</td>



<td>79</td></tr>
<tr>
<td>42.</td>
<td>Squeak!</td>
<td>20:58</td>



<td>16</td></tr>
<tr>
<td>43.</td>
<td>ˇ&lt;</td>
<td>20:58</td>



<td>80</td></tr>
</tbody></table>

<br />

							<h3>Pořadí v kategorii <em>Víc bodů</em></h3>

							<table className="nobreak"><thead><tr><th>#</th>
<th>Název týmu</th>
<th>(+) body</th>
<th>(-) body</th>
<th>Celkem</th></tr></thead><tbody><tr><td>1.</td>
<td><b>Team Avatar</b></td>


<td>92</td>
<td>0</td>
<td>92</td></tr>
<tr><td>2.</td>
<td>V.I.P.</td>


<td>89</td>
<td>0</td>
<td>89</td></tr>
<tr><td>3.</td>
<td>Kočka s Out of memory errorem</td>


<td>85</td>
<td>-4</td>
<td>81</td></tr>
<tr><td>4.</td>
<td>ˇ&lt;</td>


<td>80</td>
<td>0</td>
<td>80</td></tr>
<tr><td>5.</td>
<td>Směs oříšků</td>


<td>79</td>
<td>0</td>
<td>79</td></tr>
<tr><td>6.</td>
<td>Tranquilo, señor!</td>


<td>82</td>
<td>-9</td>
<td>73</td></tr>
<tr><td>7.</td>
<td>Šestý nesmysl</td>


<td>74</td>
<td>-2</td>
<td>72</td></tr>
<tr><td>8.</td>
<td>B</td>


<td>79</td>
<td>-9</td>
<td>70</td></tr>
<tr><td>9.</td>
<td>Wake of Gods</td>


<td>67</td>
<td>-4</td>
<td>63</td></tr>
<tr><td>10.</td>
<td>Poslední operand</td>


<td>64</td>
<td>-5</td>
<td>59</td></tr>
<tr><td>11.</td>
<td>Trosečníci</td>


<td>57</td>
<td>-7</td>
<td>50</td></tr>
<tr><td>12.</td>
<td>nechci-uvést</td>


<td>60</td>
<td>-11</td>
<td>49</td></tr>
<tr><td>13.</td>
<td>Klokani hledají poklady</td>


<td>52</td>
<td>-4</td>
<td>48</td></tr>
<tr><td>14.</td>
<td>Psychokopytníci</td>


<td>51</td>
<td>-5</td>
<td>46</td></tr>
<tr><td>15.</td>
<td>Zebra řez</td>


<td>53</td>
<td>-10</td>
<td>43</td></tr>
<tr><td>16.</td>
<td>Freezy Bee</td>


<td>52</td>
<td>-12</td>
<td>40</td></tr>
<tr><td>17.</td>
<td>Red Alert</td>


<td>39</td>
<td>0</td>
<td>39</td></tr>
<tr><td>18.</td>
<td>Kočky</td>


<td>38</td>
<td>0</td>
<td>38</td></tr>
<tr><td>19.</td>
<td>Kanonický divisor</td>


<td>45</td>
<td>-8</td>
<td>37</td></tr>
<tr><td>20.</td>
<td>Dršťkový Havran Masového Kašpárka</td>


<td>42</td>
<td>-6</td>
<td>36</td></tr>
<tr><td>21.</td>
<td>O stupeň navíc</td>


<td>38</td>
<td>-4</td>
<td>34</td></tr>
<tr><td>22.</td>
<td>Hakuna Matata</td>


<td>35</td>
<td>-4</td>
<td>31</td></tr>
<tr><td>23.</td>
<td>Za pět minut dvanáct</td>


<td>43</td>
<td>-13</td>
<td>30</td></tr>
<tr><td>24.</td>
<td>Spící Volové</td>


<td>31</td>
<td>-4</td>
<td>27</td></tr>
<tr><td>25.</td>
<td>Stokaři</td>


<td>31</td>
<td>-6</td>
<td>25</td></tr>
<tr><td>26.</td>
<td>O.H.L.A.</td>


<td>33</td>
<td>-8</td>
<td>25</td></tr>
<tr><td>27.</td>
<td>3 s kouskem fosforu</td>


<td>41</td>
<td>-17</td>
<td>24</td></tr>
<tr><td>28.</td>
<td>Na výletě</td>


<td>32</td>
<td>-11</td>
<td>21</td></tr>
<tr><td>29.</td>
<td>mrkve z tykve</td>


<td>29</td>
<td>-8</td>
<td>21</td></tr>
<tr><td>30.</td>
<td>FOKA</td>


<td>36</td>
<td>-15</td>
<td>21</td></tr>
<tr><td>31.</td>
<td>Happy Matfyz Friends</td>


<td>36</td>
<td>-17</td>
<td>19</td></tr>
<tr><td>32.</td>
<td>Krabice ve slevě</td>


<td>26</td>
<td>-7</td>
<td>19</td></tr>
<tr><td>33.</td>
<td>Kvapící Fronkáros</td>


<td>39</td>
<td>-22</td>
<td>17</td></tr>
<tr><td>34.</td>
<td>Společenští poustevníci</td>


<td>23</td>
<td>-7</td>
<td>16</td></tr>
<tr><td>35.</td>
<td>Squeak!</td>


<td>26</td>
<td>-10</td>
<td>16</td></tr>
<tr><td>36.</td>
<td>Nic Nás Nenapadlo</td>


<td>19</td>
<td>-4</td>
<td>15</td></tr>
<tr><td>37.</td>
<td>Život je změna spinu</td>


<td>29</td>
<td>-18</td>
<td>11</td></tr>
<tr><td>38.</td>
<td>Degen a spol</td>


<td>21</td>
<td>-11</td>
<td>10</td></tr>
<tr><td>39.</td>
<td>Večernice</td>


<td>28</td>
<td>-18</td>
<td>10</td></tr>
<tr><td>40.</td>
<td>poTrati</td>


<td>16</td>
<td>-10</td>
<td>6</td></tr>
<tr><td>41.</td>
<td>Kdo se přidá?</td>


<td>19</td>
<td>-16</td>
<td>3</td></tr>
<tr><td>42.</td>
<td>Ztraceni na severu</td>


<td>19</td>
<td>-17</td>
<td>2</td></tr>
<tr><td>43.</td>
<td>Subdivize XVIII</td>


<td>22</td>
<td>-20</td>
<td>2</td></tr>
</tbody></table>


						</div>
					</section>
				</div>
			</Layout>
		)
	}
}
export default Vysledky
