import React from 'react'
import FormMessages from './FormMessages';

class TeamForm extends React.Component {
    constructor(props) {
        super();
        this.revealInfo = this.revealInfo.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = props.user || {
            members: {}
        };
    }    

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked ? target.value : 0
            : target.value;

        const name = target.name;

        let matches;
        if ( matches = name.match(/(members)\[([0-9a-z]+)\]/) ) {
            const members = this.state.members;
            members[matches[2]] = value;

            this.setState({
                members: members
            })
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    revealInfo( e ) {
        e.preventDefault()
        const info = document.querySelector('#info')
        info.removeAttribute('style')
    }

    render() {
        return (                 
            <form onSubmit={this.props.handleSubmit}>
                <p>Údaje s hvězdičkou jsou povinné. Vše lze až do 1. 6. 2019 měnit.</p>
                <label htmlFor="name">Název týmu*</label>
                <input type="text" maxLength="60" name="name" id="name" value={this.state.name || ''} onChange={this.handleInputChange} autocomplete="off" required />

                {this.state.id ? <p>Pokud nechcete heslo měnit, nemusíte ho při editaci údajů zadávat.</p> : null}

                <label htmlFor="password">Heslo{this.state.id ? '' :'*'}</label>
                <input type="password" name="password" id="password" required={! this.state.id} onChange={this.handleInputChange} />

                <label htmlFor="password2">Heslo (znovu){this.state.id ? '' :'*'}</label>
                <input type="password" name="password2" id="password2" required={! this.state.id} onChange={this.handleInputChange} />

                <hr />

                <label htmlFor="mail">Kontaktní e-mail*</label>
                <input type="email" name="mail" id="mail" required value={this.state.mail || ''} onChange={this.handleInputChange} />

                <label htmlFor="phone">Kontaktní telefon*</label>
                <input type="text" name="phone" id="phone" required value={this.state.phone || ''} onChange={this.handleInputChange} />

                <hr />

                <label htmlFor="player_1">Hráč 1</label>
                <input type="text" maxLength="60" name="members[0]" id="player_1" value={this.state.members[0] || ''} onChange={this.handleInputChange} />

                <label htmlFor="player_2">Hráč 2</label>
                <input type="text" maxLength="60" name="members[1]" id="player_2" value={this.state.members[1] || ''} onChange={this.handleInputChange} />

                <label htmlFor="player_3">Hráč 3</label>
                <input type="text" maxLength="60" name="members[2]" id="player_3" value={this.state.members[2] || ''} onChange={this.handleInputChange} />

                <label htmlFor="player_4">Hráč 4</label>
                <input type="text" maxLength="60" name="members[3]" id="player_4" value={this.state.members[3] || ''} onChange={this.handleInputChange} />

                <p>Můžete vzít ještě navíc jednoho <a href="/faq#paty" onClick={this.revealInfo}>úplného nováčka</a>.</p>
                <p id="info" style={{ display: 'none' }}>Místo zamýšlíme hlavně pro někoho, komu chcete ukázat váš koníček. Ale vezměte třeba i kamaráda, který sice byl na Sendviči před deseti roky, ale máte chuť si s ním zase zaluštit. Nebo někoho, kdo šel šifrovací trail a přijde mu to fajn zkusit dělat celý den. Obsazení nejsilnějšího možného hráče, který se do této rozmazané škatulky vejde, nepovažujeme za smysl její existence.</p>

                <label htmlFor="player_5">Nováček navíc</label>
                <input type="text" maxLength="60" name="members[navic]" id="player_5" value={this.state.members['navic'] || ''} onChange={this.handleInputChange} />

                <hr />

                <p>V cíli (<a href="https://mapy.cz/s/3qCpq" rel="noopener noreferrer" target="_blank">mapa</a>) se dá přespat, nic navíc to nestojí, ale počet míst je omezený. Dejte nám prosím vědět, kolik míst vám máme rezervovat. Můžete zatím hodnotu vyplnit předběžně a až finalizujete plány, zatrhněte "Rezervuji místa na spaní závazně".</p>

                <label htmlFor="sleep">Míst na spaní</label>
                <input type="number" min="0" max="5" name="sleep" id="sleep" value={this.state.sleep} readOnly={'1' === this.state.sleep_lock} onChange={this.handleInputChange} />

                <input type="checkbox" name="sleep_lock" id="sleep_lock" value="1" checked={'1' === this.state.sleep_lock} onChange={this.handleInputChange} />
                <label htmlFor="sleep_lock">Rezervuji místa na spaní závazně</label>

                <hr />

                <label htmlFor="message">Volitelná zpráva ostatním týmům</label>
                <input type="text" maxLength="255" name="message" id="message" value={this.state.message || ''} onChange={this.handleInputChange} />
                <p>Zobrazuje se v seznamu týmů. Může se hodit, když sháníte posilu, nebo se prostě chcete před hrou chvástat. :)</p>

                <FormMessages errors={this.props.errors} successMessage={this.props.successMessage} />

                <p className="center"><input type="submit" value={this.state.id ? 'Upravit údaje' : 'Registrovat tým'} /></p>
            </form>
        )
    }
}

export default TeamForm
