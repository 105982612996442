import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'

const Thanks = (props) => (
    <Layout>
        <SEO
        title="Poděkování"
        description="Poděkování po hře k šifrovací hře Navíc, která se konala v Praze 8. června 2019."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Poděkování</h2>
                    </header>
					<p>Za přípravou každé šifrovačky je hromada práce a tu bychom nikdy sami nezvládli, kdybychom neměli podporu a pomoc. A za pomoc se sluší poděkovat.</p>
                    <p>Při vymýšlení herních konceptů a dalších prvků jsme měli inspiraci zejména ve výborné brněnské šifrovačce <a href="http://krtcinorou.cz/">Krtčí norou</a>, jejímž organizátorům, zejména Vítkovi a Honzovi, bychom chtěli vyjádřit naše díky v první řadě.</p>
					<p>Základem šifrovací hry jsou - a snad i vždy budou - šifry. A abychom vás nenechali luštit něco, co obsahuje slepé a nežádoucí cesty, trojské koně, očividné chyby nebo abyste neluštili šifry, které prostě nejsou dobré, potřebovali jsme je otestovat.</p>
					<p><b>S testem letošních šifer nám moc pomohli</b> následující testeři: Martina Pomikálková, Jiří Řihák a Dalibor Šmíd, Martin Mach, Lenka Stará a Dominik Tělupil, Jana Pačísková, Ondřej Šmejkal a Míla Votradovec, Veronika Petrážová a Martin Petráž, Jiří Votinský, Petr Chlubna, Kristýna Zahradníčková, Jan Staněk a Tereza Přibáňová, Honza Klusáček, Matěj Klusáček a Markéta Müllerová, Monika a Tomáš Pavlíkovi, Monika Steinová a Majk Švagerka a na dálku přes kus světa Mikuláš Zelinka. Těm za jejich rady a doporučení patří náš velký dík.</p>
					<p>Neumíme se rozpůlit, což by nám občas přišlo vážně vhod, a proto jsme potřebovali pomoc i přímo na hře. Ať šlo o dozor na stanovištích, o rozvoz a roznos šifer nebo o pomoc s čímkoliv dalším, mohli jsme se spolehnout na náš <b>B-tým ve složení</b>: Paču, Luboš, Denča, Radek, Áňa a Honza. Díky, parto!</p>
                    <p>Nakonec jsme pro hru museli najít místo, připravit materiály nebo půjčit vybavení. A pokud jste sami někdy něco organizovali, víte, jak důležité je mít někoho spolehlivého, s kým se vám dobře spolupracuje. Takovými spolehlivými osobami pro nás byli:</p>
					<ul>
						<li>Tiskařská firma <a href="http://www.kopirovani-havranek.cz/">Kopírování Havránek</a> - obracíme se na ně opakovaně a vždy dokázali bez problémů uspokojit naše tiskové požadavky.</li>
						<li>Kolektiv <a href="https://4pvs.nipax.cz/">4. Přístavu</a> vodních skautů Jana Nerudy - poskytnutí jedinečných cílových prostor.</li>
						<li>Tiskárna <a href="http://www.potisk.com/">potisk.com</a> - vstřícný přístup a rychlý potisk herních triček.</li>
						<li>Odborová organizace <a href="http://www.ipp.cas.cz/">ÚFP AV ČR</a> - zapůjčení výčepu.</li>
						<li><a href="https://fykos.cz/">Fyzikální korespondenční seminář</a> MFF UK - zapůjčení řezačky.</li>
					</ul>
                    <p>Po hře pro nás na <a href="https://statek.seslost.cz/navic-2019/statistiky-sifer">Statku</a> zpracoval herní statistiky Tom Kuča. Děkujeme, nebereme to jako samozřejmost!</p>
					<p>První ročník šifrovací hry Navíc by ale nemohl proběhnout především bez vás, hráčů. Poslední z díků proto patří vám. Za vaše nadšení, se kterým jste se do hry pustili, za vaši důvěru, kterou jste do nás jako organizátorů vložili a také za pochopení, které jste měli, pokud se něco třeba ne úplně podařilo. Hru tvoříme pro vás, a tak velmi doufáme, že vás bavila a že na ni budete rádi vzpomínat.</p>
					<p>Pod výše uvedené se podepisují vděční organizátoři Aleš Podolník, Jarek “Dero” Polakovič a Šárka a Michal Jahodovi.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Thanks
