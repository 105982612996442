import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'

const Thanks = (props) => (
    <Layout>
        <SEO
        title="Reportáze &amp; Fotky"
        description="Reportáže a fotogalerie ze hry Navíc, která se konala v Praze 8. června 2019."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Reportáže &amp; Fotky</h2>
                    </header>
					<ul>
						<li><a href="https://photos.app.goo.gl/ciM3hK7Gfw7H1tyd6" target="_blank">Orgovská fotogalerie</a></li>
						<li><a href="https://docs.google.com/document/d/14lWJb8LOoyUQNfNsRXucZ1qQK0Ki3iG9gUgQ_JdPHps/edit" target="_blank">Reportáž s reportáží navíc od Hlavně se nezranit</a></li>
					</ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Thanks
