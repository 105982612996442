import axios from 'axios'

export default {
    get: function( route, callback ) {
        axios.get( process.env.GATSBY_NAVIC_API_URL + route )
            .then( callback );
    },

    post: function( route, data, callback ) {
        axios.post( process.env.GATSBY_NAVIC_API_URL + route, data )
            .then( callback );
    }
}
