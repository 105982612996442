/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const navigateToHash = (location) => {
  if (location.hash) {
    setTimeout(() => {
        console.log(`${location.hash}`)
      document.querySelector(`${location.hash}`).scrollIntoView();
    }, 0);
  }
}

exports.onRouteUpdate = navigateToHash;