import React from 'react'

class ListTeam extends React.Component {
    render() {
        const message = this.props.data.message ? <p><em>„{this.props.data.message}“</em></p> : '';
        const members = JSON.parse( this.props.data.members );
        let membersArray = [];

        for ( let key of [ '0', '1', '2', '3', 'navic' ] ) {
            if ( members[key] ) {
                membersArray.push( 'navic' === key ? 'a navíc ' + members[key] : members[key] );
            }
        }
        return (
            <div className={`team${'1' === this.props.data.cancelled ? ' cancelled' : ''}`}>
                <h3>{this.props.index + 1}. {this.props.data.name} {'1' === this.props.data.paid ? <span title="Zaplaceno">💰</span> : null }{'1' === this.props.data.cancelled ? <span>(nehrající)</span> : null }</h3>
                <p>{membersArray.join(', ').replace(', a navíc', ' a navíc')}</p>
                {message}
            </div>
        )
    }
}

export default ListTeam
