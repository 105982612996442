import React from 'react'
import FormMessages from './FormMessages';

class PasswordResetForm extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <form onSubmit={this.props.resetCallback}>
                <label htmlFor="name">Název týmu*</label>
                <input type="text" maxLength="60" name="name" id="name" required />

                <label htmlFor="name">Registrační e-mail*</label>
                <input type="text" maxLength="255" name="mail" id="mail" required />

                <FormMessages errors={this.props.errors} successMessage={this.props.successMessage} />

                <p className="center"><input type="submit" value="Zažádat o heslo" /></p>
            </form>
        )
    }
}

export default PasswordResetForm
