import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Banner />

                <div id="main">
                </div>
            </Layout>
        )
    }
}

export default HomeIndex