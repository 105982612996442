import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import TeamForm from '../components/TeamForm';
import api from '../helpers/api'

class Registrace extends React.Component {
    constructor() {
        super();
        this.state = {
            errors: [],
            successMessage: '',
            teamsRegistered: 0,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);

        api.post( '/register', data, ( response ) => {
            const data = response.data;
            if ( 'error' === data.status ) {
                this.setState({
                    errors: data.data,
                    successMessage: '',
                });
            } else {
                this.setState({
                    errors: [],
                    successMessage: 'Tým zaregistrován!',
                });
            }
        } );
    }

    render() {
        return (
            <Layout>
                <SEO
                title="Registrace týmu"
                description="Registrace na šifrovací hru Navíc, která se koná v Praze 8. června 2019."
                />

                <div id="main">
                    <section id="one">
                        <div className="inner">
                            <header className="major">
                                <h2>Registrace nového týmu</h2>
                            </header>
							<p>Registrace byla uzavřena.</p>
                            {/* <TeamForm handleSubmit={this.handleSubmit} successMessage={this.state.successMessage} errors={this.state.errors} /> */}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default Registrace
