import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import SEO from '../components/Seo'

const Faq = (props) => (
    <Layout>
        <SEO
        title="Časté otázky"
        description="Časté otázky k šifrovací hře Navíc, která se koná v Praze 8. června 2019."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Časté otázky</h2>
                    </header>
                    <p className="strong">Co si máme představit pod pojmem „šifrovačka pro každého“?</p>
                    <p>Znamená to, že tvoříme <a href="https://sifrovacky.cz/o-sifrovackach/co-jsou-sifrovacky/">šifrovačku</a> tak, aby si dobře zahráli hráči všech výkonnostních úrovní.</p>
                    <p>Základním prvkem hry bude hlavní trasa. Na té můžete čekat jednodušší a středně těžké šifry, které s občasnou dopomocí nápovědy zvládnou vyluštit i začátečníci.</p>
                    <p>Mimo hlavní trasu se ve hře budou vyskytovat tzv. <em>úkoly navíc</em>. Ty žádný předem daný charakter nemají. Můžete očekávat jak menší šifřičky vhodné k doplnění bodů pro „nákup“ nápověd, tak obtížné šifry pro frajery mířící za vítězstvím v kategorii <em>Víc bodů</em>, různé chytáky, úchylárny i nešifrovací úkoly.</p>

                    <a name="kategorie"></a>
                    <p className="strong">Můžete trochu rozvést ty dvě kategorie hodnocení?</p>
                    <p>Jasně. Po skončení hry z týmů, které dokončí hru, sestavíme dvě výsledkové listiny. Hra tedy bude mít i dva vítěze.</p>
                    <p>V kategorii <em>Na čas</em> se hodnotí výhradně čas dokončení hry úspěšným projitím hlavní trasy. Nepřihlíží se k žádným nápovědám (ani řešením), které na trase použijete. Může být výhodné použít strategickou nápovědu pro urychlení luštění, pokud na ni máte body.</p>
                    <p>V kategorii <em>Víc bodů</em> se týmy, které dokončí hru, hodnotí podle počtu bodů, které mají na kontě v okamžiku odevzdání cílového hesla. Body sbíráte plněním <em>úkolů navíc</em>, tj. mimo hlavní trasu hry. Bacha, použitím nápověd a řešení body zase ztrácíte!</p>
                    <p>Pár upřesňujících poznámek:</p>
                    <ul>
                        <li>Odevzdáním cílového hesla pro vás hra končí. Není pak už možné pokračovat řešením <em>úkolů navíc</em> a sbírat tak body do kategorie <em>Víc bodů</em>.</li>
                        <li>Při remíze v kategorii <em>Víc bodů</em> rozhoduje čas dokončení hry (dříve je lépe).</li>
                    </ul>

                    <p className="strong">Takže… pokud jsme fakt dobří, můžeme zvítězit v obou kategoriích?</p>
                    <p>Teoreticky jo. Ale to fakt nedáte. :)</p>

                    <a name="nezasifrovane-ukoly"></a>
                    <p className="strong">Co znamená, že informace o <em>úkolech navíc</em> jsou poskytovány v nezašifrované podobě?</p>
                    <p>Znamená to, že zadání <em>úkolů navíc</em> budete dostávat na stanovištích hlavní trasy explicitně. Dostanete postupně jednotlivá zadání a budete si vědomi počtu bodů, které splněním jednotlivých <em>úkolů navíc</em> můžete získat. Neznamená to však, že budete vždy bez přemýšlení vědět, jakým způsobem lze úkol splnit.</p>
                    
                    <p className="strong">Co si máme vzít s sebou?</p>
                    <p>Nepůjde to bez tužky a chytrého telefonu s internetem.</p>
                    <p>Pro usnadnění luštění dále doporučujeme čtverečkovaný papír, izolepu, nůžky, pravítko, kružítko, úhloměr, zvýrazňovače, fólie.</p>
                    <p>Pro navigaci se hodí Mapy.cz v mobilu (<a href="https://play.google.com/store/apps/details?id=cz.seznam.mapy">Android</a>, <a href="https://itunes.apple.com/cz/app/mapy-cz/id411411020">iOS</a>), které při přípravě hry používáme i my. Protože navigace, googlení a focení selfíček žere baterku, může se vám hodit i powerbanka.</p>
                    <p>Nezapomeňte i na osobní komfort a vezměte si pohodlné boty na městské a mírně terénní chození. Dál něco na sezení (třeba karimatku) a na snězení (třeba svačinu). Hodit se může i deštník proti slunci (a nedejbože dešti) a vějíř proti vedru.</p>

                    <p className="strong">Jak bude vypadat trasa, zvládneme to ujít?</p>
                    <p>Zvládnete. Délka hlavní trasy se pohybuje mezi 10–15 kilometry, je dobře sjízdná kočárkem a vede městem a dobře prostupnou městskou zelení. Můžete si z postupu hlavní trasou hry udělat třeba hezký rodinný výlet, stanoviště jsou na to uzpůsobena. Nezapomeňte, že děti do 12 let věku se nepočítají do počtu členů týmu.</p>
                    <p><em>Úkoly navíc</em> vás výjimečně mohou poslat na nějaká navigačně náročnější nebo hůře schůdná místa, tak s tím počítejte, pokud se chcete věnovat i této části hry.</p>

                    <a name="paty"></a>
                    <p className="strong">Koho si do týmu můžeme vzít jako pátého hráče navíc?</p>
                    <p>Místo zamýšlíme hlavně pro někoho, komu chcete ukázat váš koníček. Ale vezměte třeba i kamaráda, který sice byl na Sendviči před deseti roky, ale máte chuť si s ním zase zaluštit. Nebo někoho, kdo šel šifrovací trail a přijde mu to fajn zkusit dělat celý den. Obsazení nejsilnějšího možného hráče, který se do této rozmazané škatulky vejde, nepovažujeme za smysl její existence.</p>

                    <a name="pes"></a>
                    <p className="strong">Chceme na hru vzít psa, šlo by to?</p>
                    <p>Na hru samotnou psa vzít není problém, ale do cíle mají psi vstup zakázán. Pokud přijdete na chvíli posedět, můžete ho uvázat před brankou, ale pokud budete chtít přespávat, doporučujeme psa odvézt domů nebo ho tam rovnou nechat.</p>
                    
                    <p className="strong">Můžeme se na hře rozdělit a luštit paralelně víc úloh?</p>
                    <p>Úloh můžete souběžně luštit, kolik jenom zvládnete. Tým však smíte fyzicky rozdělit maximálně na dvě části. Přečtěte si <Link to="/pravidla#co-ano-co-ne">povolené a zakázané postupy</Link> v pravidlech.</p>

                    <p className="strong">Jak bude vypadat večerní program a přespání v cíli?</p>
                    <p>Bude občerstvení (pivo, limo, něco malého na zakousnutí). Budeme mít oheň. Přineseme různé deskovky. A dál to záleží na vás – můžete si přinést kytaru a hrát a zpívat. Můžete spát pod širákem v centru Prahy. Můžete se zašít na půdu a něco si zahrát, třeba i vlastního. Nebo jen probírat nejnovější drby ze šifrovací komunity či lákat hráče na svoji hru. A samozřejmě se podívat na řešení šifer, vysvětlit nám, že váš postup byl lepší, nebo pogratulovat vítězům. Budeme rádi, když se zdržíte, co to půjde, místa je dost.</p>
                    <p>K přespání se může hodit vlastní spacák/karimatka, ale pokud se rozhodnete na poslední chvíli, něco se pro vás v cíli najde. Pokud bude pršet nebo zima, budeme pod střechou. Před hrou se můžete v cíli stavit a uložit si tam svoje věci na přespání.</p>

                    <p className="strong">Dá se v okolí startu nebo cíle zaparkovat?</p>
                    <p>Ano. Na Praze 3 <a href="https://www.praha3.cz/getFile/id:259839/lastUpdateDate:2018-01-22+10:03:16/image.jpg">o víkendu můžete parkovat</a> v modrých i fialových zónách zdarma.</p>
                    <p>V okolí startu nejsnáze zaparkujete v ulici Na Balkáně a okolí. Poblíž cíle, zejména budete-li přespávat, doporučujeme parkování pod obytnými budovami, tj. ve východní části ulice Pitterova, v ulici Ke Kapslovně, případně na západním konci ulice Malešické.</p>
                    <p>Pozor, kvůli uzavírce na Krejcárku se do ulice Na Balkáně ani do ulice U Kněžské louky nedá odbočit z ul. Spojovací. Pokud budete parkovat v oblasti Jarova, musíte to vzít přes Koněvovu. Doporučujeme předem nastudovat <a href="https://mapy.cz/s/3sTdr">místní jednosměrky</a>.</p>

                    <p className="strong">Pošlete ještě před začátkem hry nějaké „Poslední informace“?</p>
                    <p>Pošleme. Čekat je můžete nejpozději ve středu před hrou.</p>

                    <a name="webinfo"></a>
                    <p className="strong">Kam budeme zaznamenávat postup hrou?</p>
                    <p>Dosažení stanoviště nebo získání hesla z <em>úkolu navíc</em> nahlásíte na webové stránce (tzv. webinfo), jejíž adresu vám pošleme spolu s „Posledními informacemi“.</p>

                    <p className="strong">A jak se <a href="https://statek.seslost.cz/o-statku" target="_blank" rel="noopener noreferrer">Statek</a> popere se dvěma kategoriemi hodnocení?</p>
                    <p>Nepopere. Před hrou sice uvidíte <a href="https://statek.seslost.cz/navic-2019/nasazeni" target="_blank" rel="noopener noreferrer">nasazení</a>, ale po hře se žádné body rozdělovat (ani brát) nebudou. Tak se nebojte experimentovat se složením týmu.</p>
                    
                    <p className="strong">Kdo jste, orgové?</p>
                    <p>Aleš, Dero, Mike a Šárka. Organizovali jsme například několik <a href="https://matrix.velkyvuz.cz/archiv">Matrixů</a>. Dneska už na přípravu akce typu Matrix nemáme čas, ale tvořit se nám stále chce. A tak pořádáme menší denní hru, která se nám určitě nevymkne z kloubů a nepřeroste přes hlavu. :)</p>

                    <p className="strong">Máme ještě otázky navíc…</p>
                    <p>Ano? Pošlete nám mail na <a href="mailto:navic@sifrovacky.cz">navic@sifrovacky.cz</a>.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Faq
