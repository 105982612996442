import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import DiscussionThread from '../components/DiscussionThread'

const Discussion = (props) => (
    <Layout>
        <SEO
        title="Diskuse"
        description="Diskuse k šifrovací hře Navíc, která se koná v Praze 8. června 2019."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Diskuse</h2>
                    </header>
					<DiscussionThread thread="main" />
                </div>
            </section>
        </div>

    </Layout>
)

export default Discussion
